/* eslint-disable indent */
import './style.css'
import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import * as dat from 'dat.gui'
import { AdditiveBlending, Float32BufferAttribute } from 'three'

/**
 * Base
 */
// Debug
const gui = new dat.GUI({
    width: 400,
    closed: true
})

const textureLoader = new THREE.TextureLoader()
const shape = textureLoader.load('/particleShape/1.png')

// Canvas
const canvas = document.querySelector('canvas.webgl')

// Scene
const scene = new THREE.Scene()


//Galaxy Generator

const parameters = {}

parameters.count = 70000
parameters.size = 0.01
parameters.radius = 5
parameters.branches = 10
parameters.spin = 1
parameters.randomness = 0.3
parameters.randomnessPower = 5
parameters.stars = 9000
parameters.starColor = '#1b3984'
parameters.insideColor = '#01dbdb'
parameters.outsideColor = '#008080'

gui.add(parameters, 'count').min(100).max(100000).step(100).onChange(generateGalaxy).name('stars in galaxy')
gui.add(parameters, 'stars').min(0).max(100000).step(100).onChange(generateBgStars).name('background stars')
gui.addColor(parameters, 'starColor').onChange(generateBgStars).name('color of stars')
gui.add(parameters, 'size').min(0.001).max(0.1).step(0.001).onChange(generateGalaxy).name('size of stars in galaxy')
gui.add(parameters, 'radius').min(1).max(10).step(1).onChange(generateGalaxy).name('radius of galaxy')
gui.add(parameters, 'branches').min(1).max(10).step(1).onChange(generateGalaxy).name('branches in galaxy')
gui.add(parameters, 'spin').min(-5).max(5).step(0.001).onChange(generateGalaxy).name('spin of the galaxy')
gui.add(parameters, 'randomness').min(0).max(2).step(0.01).onChange(generateGalaxy)
gui.add(parameters, 'randomnessPower').min(1).max(10).step(1).onChange(generateGalaxy)
gui.addColor(parameters, 'insideColor').onChange(generateGalaxy).name('color of core')
gui.addColor(parameters, 'outsideColor').onChange(generateGalaxy).name('color of branches')


let bgStarsGeometry = null
let bgStarsMaterial = null
let bgStars = null

//Background stars
function generateBgStars(){

    if(bgStars!==null){
        bgStarsGeometry.dispose()
        bgStarsMaterial.dispose()
        scene.remove(bgStars)
    }

    bgStarsGeometry = new THREE.BufferGeometry()
    const bgStarsPositions = new Float32Array(parameters.stars * 3)

    for(let j = 0; j<parameters.stars; j++){
        bgStarsPositions[j*3 + 0] = (Math.random() - 0.5) * 20
        bgStarsPositions[j*3 + 1] = (Math.random() - 0.5) * 20
        bgStarsPositions[j*3 + 2] = (Math.random() - 0.5) * 20
    }

    bgStarsGeometry.setAttribute('position', new THREE.BufferAttribute(bgStarsPositions, 3))

    bgStarsMaterial = new THREE.PointsMaterial({
        color: 'white',
        size: parameters.size,
        depthWrite: false,
        sizeAttenuation: true,
        blending: AdditiveBlending,
        color: parameters.starColor,
        transparent: true,
        alphaMap: shape
    })

    bgStars = new THREE.Points(bgStarsGeometry, bgStarsMaterial)

    scene.add(bgStars)
}

generateBgStars()




//gALAXY GENerator
let geometry = null
let material = null
let points = null


function generateGalaxy(){

    if(points !== null){
        geometry.dispose()
        material.dispose()
        scene.remove(points)
    }

    geometry = new THREE.BufferGeometry()

    const positions = new Float32Array(parameters.count *3)
    const colors = new Float32Array(parameters.count *3)

    const colorInside = new THREE.Color(parameters.insideColor)
    const colorOutside = new THREE.Color(parameters.outsideColor)

    for(let i=0; i<parameters.count; i++){

        //Position
        const x = Math.random() * parameters.radius
        const branchAngle = (i % parameters.branches) / parameters.branches * 2 * Math.PI
        const spinAngle = x * parameters.spin

        const randomX = Math.pow(Math.random(), parameters.randomnessPower) * (Math.random()<0.5 ? 5: -5) 
        const randomY = Math.pow(Math.random(), parameters.randomnessPower) * (Math.random()<0.5 ? 0.5: -0.5) 
        const randomZ = Math.pow(Math.random(), parameters.randomnessPower) * (Math.random()<0.5 ? 5: -5)

        positions[i*3] = Math.sin(branchAngle + spinAngle) * x + randomX
        positions[i*3 + 1] = randomY
        positions[i*3 + 2] = Math.cos(branchAngle + spinAngle) * x + randomZ

        //Color

        const mixedColor = colorInside.clone()
        mixedColor.lerp(colorOutside, x / parameters.radius)

        colors[i*3 + 0] = mixedColor.r
        colors[i*3 + 1] = mixedColor.g
        colors[i*3 + 2] = mixedColor.b
    }

    geometry.setAttribute('position', new THREE.BufferAttribute(positions, 3))
    geometry.setAttribute('color', new THREE.BufferAttribute(colors, 3))

    material = new THREE.PointsMaterial({
        color: 'white',
        size: parameters.size,
        depthWrite: false,
        sizeAttenuation: true,
        blending: AdditiveBlending,
        vertexColors: true,
        transparent: true,
        alphaMap: shape
    })

    points = new THREE.Points(geometry, material)
    scene.add(points)


}

generateGalaxy()

/**
 * Sizes
 */
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

window.addEventListener('resize', () =>
{
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})

/**
 * Camera
 */
// Base camera
const camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height, 0.1, 100)
camera.position.x = 3
camera.position.y = 3
camera.position.z = 3
scene.add(camera)

// Controls
const controls = new OrbitControls(camera, canvas)
controls.enableDamping = true

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    canvas: canvas
})
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

/**
 * Animate
 */
const clock = new THREE.Clock()

const tick = () =>
{
    const elapsedTime = clock.getElapsedTime()

    //Update the camera
    points.rotation.y = elapsedTime*0.3
    bgStars.rotation.y = - elapsedTime*0.05

    // Update controls
    controls.update()

    // Render
    renderer.render(scene, camera)

    // Call tick again on the next frame
    window.requestAnimationFrame(tick)
}

tick()

// ! Above -> canvas, Below -> rest
// elements
const heading = document.querySelector('.heading')
const mainHeading = document.getElementById('main_head')
const junior = document.getElementById('junior')
const father = document.getElementById('father')
const nerd = document.getElementById('nerd')
// nav elements
const about = document.getElementById('about')
const skills = document.getElementById('skills')
const work = document.getElementById('work')
const contact = document.getElementById('contact')
// body elements
const aboutSection = document.getElementById('about-section')
const skillsSection = document.getElementById('skills-section')
const workSection = document.getElementById('work-section')
const contactSection = document.getElementById('contact-section')
const sections = [aboutSection, skillsSection, workSection, contactSection]
// buttons
const aboutButton = document.getElementById('ab-button')
const skillsButton = document.getElementById('sk-button')
const workButton = document.getElementById('wk-button')
const contactButton = document.getElementById('ct-button')
const buttons = [aboutButton, skillsButton, workButton, contactButton]

//* Re-use Functions
function removeFadeIn(...elements){
  elements.map((e) => {
    if (e.classList.contains('fade-in')) {
      e.classList.remove('fade-in')
      e.classList.add('fade-out')
    }
  })
}

function removeFadeOut(e){
  if (e.classList.contains('fade-out')) {
    e.classList.remove('fade-out')
  }
  e.classList.add('fade-in')
}

function mainFadeIn(){
  // Add fade-in class after a short delay to allow particles to load
  setTimeout(() => {
    mainHeading.classList.add('fade-in')
    setTimeout(() => {
      junior.classList.add('fade-in')
      setTimeout(() => {
        father.classList.add('fade-in')
        setTimeout(() => {
          nerd.classList.add('fade-in')
        }, 1500)
      }, 1500)
    }, 1500)
  }, 50)
}

function heroSubtitle(){
  setTimeout(() => {
    mainHeading.style.opacity = 0
    junior.style.opacity = 0
    father.style.opacity = 0
    nerd.style.opacity = 0
  }, 500)
}

function buttonDisplay(toDisplay){
  buttons.map((b) => {
    b === toDisplay ? b.style.display = 'block' : b.style.display = 'none'
  })
}

function sectionDisplay(toDisplay){
  sections.map((s) => {
    s === toDisplay ? s.style.display = 'flex' : s.style.display = 'none'
  })
}

// event listeners
document.addEventListener('DOMContentLoaded', mainFadeIn())

about.addEventListener('click', function () {
  removeFadeIn(heading, mainHeading, junior, father, nerd, skillsSection, workSection, contactSection)
  buttonDisplay(aboutButton)
  heroSubtitle()
  sectionDisplay(aboutSection)
  cameraChange(1, -1, 1, 0, 0, 0)
  removeFadeOut(aboutSection)
})

skills.addEventListener('click', function () {
  removeFadeIn(heading, mainHeading, junior, father, nerd, aboutSection, workSection, contactSection)
  buttonDisplay(skillsButton)
  heroSubtitle()
  sectionDisplay(skillsSection)
  cameraChange(3, 1, 0, 0, -1, 2)
  removeFadeOut(skillsSection)
})

work.addEventListener('click', function () {
  removeFadeIn(heading, mainHeading, junior, father, nerd, aboutSection, skillsSection, contactSection)
  buttonDisplay(workButton)
  heroSubtitle()
  sectionDisplay(workSection)
  cameraChange(-1, -2, -3, 2, 3, 0)
  removeFadeOut(workSection)
})

contact.addEventListener('click', function () {
  removeFadeIn(heading, mainHeading, junior, father, nerd, aboutSection, skillsSection, workSection)
  buttonDisplay(contactButton)
  heroSubtitle()
  sectionDisplay(contactSection)
  // cameraChange(3, 0.8, 3, 1, -3, -2)
  cameraChange(3, 1, 3, 1, -4, -3)
  removeFadeOut(contactSection)
})

// button listeners
buttons.map((b) => {
  b.addEventListener('click', function () {
    removeFadeIn(skillsSection, workSection, contactSection, aboutSection)
    const arr = [heading, mainHeading, junior, father, nerd]
    arr.map((e) => e.classList.remove('fade-out'))
    mainFadeIn()
    cameraChange(3, 3, 3, 0, 0, 0)
  })
})

//!   TESTINGG

function cameraChange(ar1, ar2, ar3, ar4, ar5, ar6){
  const targetPosition = new THREE.Vector3(ar1, ar2, ar3) // Set your desired coordinates
  const targetLookAt = new THREE.Vector3(ar4, ar5, ar6) // Point camera is going to look at
  const duration = 2000 // Duration of the animation in milliseconds
  const startTime = performance.now()

  const updateCamera = () => {
      const currentTime = performance.now()
      const elapsed = currentTime - startTime

      if (elapsed < duration) {
          const progress = elapsed / duration
          camera.position.lerp(targetPosition, progress)

          // Update controls if needed
          controls.target.copy(targetLookAt) // Set the camera's orientation
          controls.update()

          // Render
          renderer.render(scene, camera)

          // Continue the animation
          requestAnimationFrame(updateCamera)
      } else {
          // Ensure the camera reaches the exact target position
          camera.position.copy(targetPosition)
          
          // Update controls if needed
          controls.update()
          controls.target.copy(targetLookAt)

          // Render
          renderer.render(scene, camera)
      }
  }

  // Start the animation
  requestAnimationFrame(updateCamera)
}
